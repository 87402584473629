import http from "./http";

export function getAllEvents() {
  return http.get("/events/list");
}

export function addEvent(data) {
  return http.post("/events/add", data);
}

export function getEvent(id) {
  return http.get(`/events/${id}`);
}

export function getLogsbyEvent(id) {
  return http.get(`/events/logs/${id}`);
}

export function deleteEvent(id) {
  return http.delete(`/events/delete/${id}`);
}
export function editEvents(id, data) {
  return http.patch(`/events/setstatus/${id}`, data);
}

import {
  Avatar,
  Box,
  TextField,
  Typography,
  Button,
  Grid,
  Link,
  Container,
  CardContent,
  Card,
} from "@mui/material";
import React, { useState } from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Copyright } from "../../components/Copyright";
import { useNavigate } from "react-router-dom";
import image from "../../assets/favicon.png";
import Joi from "joi";

import { toast } from "react-toastify";
import { login } from "../../services/auth";

const Login = () => {
  const navigate = useNavigate();

  const [form, setForm] = useState({
    username: "",
    password: "",
  });

  const [errors, setErrors] = useState({});

  const schema = Joi.object({
    username: Joi.string().required(),
    password: Joi.string().required(),
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.currentTarget.name]: e.currentTarget.value,
    });

    const { error } = schema
      .extract(e.currentTarget.name)
      .label(e.currentTarget.name.toUpperCase())
      .validate(e.currentTarget.value);

    if (error) {
      setErrors({
        ...errors,
        [e.currentTarget.name]: error.details[0].message,
      });
    } else {
      delete errors[e.currentTarget.name];
      setErrors(errors);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await login(form);
      if (res && res.data.status === 1) {
        if (
          res.data.object.role === "admin" ||
          res.data.object.subRole === "SubAdmin"
        ) {
          localStorage.setItem("MPC", JSON.stringify(res.data.object));
          toast.success("Successfully Logged in!");
          navigate("/dashboard");
        } else {
          toast.warning("You are not an admin");
        }
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        elevation={0} // Set elevation to 0 to remove shadows
        sx={{
          display: "flex",
          backgroundColor: "white", // Set the background color to white
        }}
      >
        {/* Left side: Image */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minWidth: "30%",
          }}
        >
          <img style={{ height: "80%" }} src={image} alt="Your Image" />
        </Box>

        {/* Right side: Login Form */}
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "70%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
              <img style={{ height: "120%" }} src={image} alt="Your Image" />
            </Avatar>
            <Typography component="h1" variant="h5" fontWeight="bold">
              Sign in to MPC
            </Typography>
            <Box component="form" onSubmit={handleSubmit}>
              <TextField
                margin="normal"
                fullWidth
                name="username"
                label="Username"
                value={form.username}
                onChange={handleChange}
                error={!!errors.username}
                helperText={errors.username}
              />
              <TextField
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type="password"
                value={form.password}
                onChange={handleChange}
                error={!!errors.password}
                helperText={errors.password}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Login;

import React from "react";
import { adminRoutes } from "../routes";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { getCurrentUser } from "../services/auth";

const NavbarButton = () => {
  const routes =
    getCurrentUser()?.role === "admin"
      ? adminRoutes
      : adminRoutes.filter(
          (r) => r.path !== "/users" && r.path !== "/users/add"
        );
  return (
    <>
      {routes
        .filter((item) => {
          return (
            typeof item.icon !== "undefined" || typeof item.text !== "undefined"
          );
        })
        .map((item, index) => (
          <ListItemButton key={index} href={item.path}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        ))}
    </>
  );
};

export default NavbarButton;

import { MenuItem, TextField } from "@mui/material";
import React from "react";

const TextFieldSelect = ({
  name,
  label,
  value,
  onChange,
  data,
  error,
  helperText,
  hasInitialValue,
  disabled,
}) => {
  return (
    <TextField
      fullWidth
      select
      name={name}
      label={label}
      value={value}
      defaultValue={hasInitialValue ? value : ""}
      onChange={onChange}
      error={error}
      helperText={helperText}
      disabled={disabled}
    >
      {data.map((option, index) => (
        <MenuItem key={index} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default TextFieldSelect;

import React from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import { IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getCurrentUser, logout } from "../services/auth";

const IconPopover = ({ anchorEl, handleClose, handleClick }) => {
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const user = getCurrentUser();

  return (
    <>
      <Typography fontWeight="bold" mr={1}>
        Hi, {user.firstName} {user.lastName}!
      </Typography>
      <IconButton color="inherit" aria-describedby={id} onClick={handleLogout}>
        <LogoutIcon />
      </IconButton>{" "}
      {/* <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Button onClick={handleLogout}>Logout</Button>
        <Typography sx={{ p: 2 }}>Logout</Typography>
      </Popover> */}
    </>
  );
};

export default IconPopover;

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import { Suspense } from "react";
import SuspenseFallback from "./components/SuspenseFallback";
import { green } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    primary: green,
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Suspense fallback={<SuspenseFallback />}>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </React.StrictMode>
    </Suspense>
  </BrowserRouter>
);

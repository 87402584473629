import DashboardIcon from "@mui/icons-material/Dashboard";
import SavingsIcon from "@mui/icons-material/Savings";
import { ReportOutlined } from "@mui/icons-material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import InventoryIcon from "@mui/icons-material/Inventory";
import BallotIcon from "@mui/icons-material/Ballot";

import React, { lazy } from "react";
import DashboardContent from "./components/DashboardContent";
import { Dashboard } from "./pages/dashboard/Dashboard";
import { getCurrentUser } from "./services/auth";
import { Navigate, useRoutes } from "react-router-dom";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import TransactionEdit from "./pages/transactions/TranctionEdit";
import { Person, VerifiedUserSharp } from "@mui/icons-material";

const PigsTable = lazy(() => import("./pages/pigs/PigsTable"));
const Transaction = lazy(() => import("./pages/transactions/Transactions"));
const Inventory = lazy(() => import("./pages/inventory/Inventory"));
const Monitoring = lazy(() => import("./pages/monitoring/Monitoring"));
const MonitoringEdit = lazy(() => import("./pages/monitoring/MonitoringEdit"));
const SalesReport = lazy(() => import("./pages/reports/SalesReport"));
const PigEdit = lazy(() => import("./pages/pigs/PigEdit"));
const PigsForm = lazy(() => import("./pages/pigs/PigsForm"));
const PigView = lazy(() => import("./pages/pigs/PigView"));

const InventoryAdd = lazy(() => import("./pages/inventory/InventoryAdd"));
const InventoryEdit = lazy(() => import("./pages/inventory/InventoryEdit"));

const Users = lazy(() => import("./pages/users/Users"));
const UsersAdd = lazy(() => import("./pages/users/UsersAdd"));
const UsersEdit = lazy(() => import("./pages/users/UsersEdit"));

const Logs = lazy(() => import("./pages/activity-logs/Logs"));

export const adminRoutes = [
  { element: <Navigate to="/dashboard" />, index: true },
  {
    icon: <DashboardIcon />,
    text: "Dashboard",
    path: "/dashboard",
    element: <Dashboard />,
  },

  {
    icon: <SavingsIcon />,
    text: "Pigs",
    path: "/pig",
    element: <PigsTable />,
  },
  {
    icon: <AccountBalanceWalletIcon />,
    text: "Sales",
    path: "/transaction",
    element: <Transaction />,
  },

  {
    path: "/transaction/edit/:id",
    element: <TransactionEdit />,
  },
  {
    icon: <InventoryIcon />,
    text: "Inventory",
    path: "/inventory",
    element: <Inventory />,
  },
  {
    path: "/inventory/add",
    element: <InventoryAdd />,
  },
  {
    path: "/inventory/edit/:id",
    element: <InventoryEdit />,
  },
  {
    path: "/monitoring/edit/:id",
    element: <MonitoringEdit />,
  },
  {
    icon: <ContentPasteSearchIcon />,
    text: "Monitoring",
    path: "/monitoring",
    element: <Monitoring />,
  },
  {
    path: "/pig/edit/:id",
    element: <PigEdit />,
  },
  {
    path: "/pig/add",
    element: <PigsForm />,
  },
  {
    path: "/pig/view/:id",
    element: <PigView />,
  },
  {
    icon: <Person />,
    text: "Users",
    path: "/users",
    element: <Users />,
  },
  {
    path: "/users/add",
    element: <UsersAdd />,
  },
  {
    path: "/users/edit/:id",
    element: <UsersEdit />,
  },
  {
    icon: <ReportOutlined />,
    text: "Reports",
    path: "/report",
    element: <SalesReport />,
  },
  {
    icon: <BallotIcon />,
    text: "Activity Logs",
    path: "/logs",
    element: <Logs />,
  },
];

export default function Router() {
  const getRoutes = () => {
    if (getCurrentUser()?.role === "admin") {
      return adminRoutes;
    } else if (getCurrentUser()?.role !== "admin") {
      return adminRoutes.filter(
        (r) => r.path !== "/users" && r.path !== "/users/add"
      );
    }
    return null;
  };

  const routes = useRoutes([
    {
      path: "/",
      element: getCurrentUser() ? (
        <DashboardContent />
      ) : (
        <Navigate to="/login" />
      ),
      children: getRoutes(),
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "*",
      element: <Navigate to="/" />,
    },
  ]);
  return routes;
}

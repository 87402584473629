import http from "./http";

export function getAllTransactions() {
  return http.get("/transactions/list");
}

export function getTransactionsbyID(id) {
  return http.get(`/transactions/${id}`);
}

export function deleteTransactionsbyID(id) {
  return http.delete(`/transactions/${id}`);
}

export function editTransactions(id, data) {
  return http.patch(`/transactions/${id}`, data);
}

export function addTransactions(data) {
  return http.post("/transactions/add", data);
}

import { Button, Grid, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import TextFieldSelect from "../../components/TextFieldSelect";
import { getUnit } from "../../constants";

const TransactionForm = ({ initialValue, onSubmit }) => {
  const [form, setForm] = useState(
    initialValue || {
      amount: 0,
      date_modified: "",
      clientName: "",
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(form);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  return (
    <Grid container spacing={2} component="form" onSubmit={handleSubmit}>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          type="number"
          name="amount"
          value={form.amount}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          name="clientName"
          value={form.clientName}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          type="date"
          name="date_modified"
          value={form.date_modified}
          onChange={handleChange}
        />
      </Grid>

      <Grid item xs={12}>
        <Button fullWidth variant="contained" type="submit">
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

export default TransactionForm;

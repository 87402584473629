import http from "./http";

export function getAllPigs() {
  return http.get("/pig/all");
}

export function addPig(data) {
  return http.post("/pig/add", data);
}

export function editPig(id, data) {
  return http.patch(`/pig/edit/${id}`, data);
}

export function getPigbyID(id) {
  return http.get(`/pig/${id}`);
}

export function setStatus(data) {
  return http.patch("/pig/setstatus", data);
}

export function getAllBreeds() {
  return http.get("/breedtypes/list");
}

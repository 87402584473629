import http from "./http";

export function login(form) {
  return http.post("/user/login", form);
}

export function register(form) {
  return http.post("/user/register", form);
}

export function logout() {
  localStorage.removeItem("MPC");
}

export function getCurrentUser() {
  return localStorage.getItem("MPC")
    ? JSON.parse(localStorage.getItem("MPC"))
    : null;
}

import { Container, Grid } from "@mui/material";
import React, { useState } from "react";
import SavingsIcon from "@mui/icons-material/Savings";
import InventoryIcon from "@mui/icons-material/Inventory";
import WidgetCard from "./dashComponents/WidgetCard";
import { getAllPigs } from "../../services/pigsServices";
import { useEffect } from "react";
import SuspenseFallback from "../../components/SuspenseFallback";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import { months } from "../../constants";
import { getAllEvents } from "../../services/eventsService";
import { getAllFeeds } from "../../services/feedsService";
import { getAllTransactions } from "../../services/transactionsService";
import LineChart from "./dashComponents/LineChart";

export const Dashboard = () => {
  const [pigs, setPigs] = useState(null);
  const [monitoringData, setMonitoringData] = useState(null);
  const [inventory, setInventory] = useState(null);
  const [transactions, setTransactions] = useState(null);

  useEffect(() => {
    getPigs();
    getMonitorings();
    getInventory();
    getTransactions();
  }, []);

  const getPigs = () => {
    getAllPigs().then((res) => {
      if (res && res.data) {
        const filteredPigs = res.data.filter((pig) => pig.isSold == false);
        setPigs(filteredPigs);
      }
    });
  };

  const getTransactions = () => {
    getAllTransactions().then((res) => {
      if (res && res.data) {
        setTransactions(res.data);
      }
    });
  };

  const getMonitorings = () => {
    getAllEvents().then((res) => {
      if (res && res.data) {
        setMonitoringData(res.data);
      }
    });
  };

  const getInventory = () => {
    getAllFeeds().then((res) => {
      if (res && res.data) {
        setInventory(res.data);
      }
    });
  };

  // const filterByCurrentMonth = (events) => {
  //   const currentMonth = new Date().getMonth() + 1;
  //   return events.filter((event) => {
  //     const eventMonth = parseInt(event.date.split("/")[0]);
  //     return eventMonth === currentMonth;
  //   });
  // };

  const filterByCurrentMonth = (events) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    return events.filter((event) => {
      // Assuming event.date is in the format "YYYY-MM-DD"
      const [year, month] = event.date.split("-");
      const eventYear = parseInt(year);
      const eventMonth = parseInt(month);

      return eventYear === currentYear && eventMonth === currentMonth;
    });
  };

  const getMonth = () => {
    const today = new Date();
    return months[today.getMonth()];
  };

  if (pigs && monitoringData && inventory && transactions) {
    const filteredTransactions = transactions.filter((obj) => {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const yearModified = new Date(obj.date_modified).getFullYear();
      return yearModified === currentYear;
    });
    //Income
    const getTotalIncomePerMonth = (transactions) => {
      let incomeByMonth = {};
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();

      transactions.forEach((transaction) => {
        if (transaction.type === "Income" && transaction.date_modified) {
          let date = new Date(transaction.date_modified);
          let year = date.getFullYear();
          let month = date.getMonth();

          if (
            year < currentYear ||
            (year === currentYear && month <= currentMonth)
          ) {
            let yearMonth = year + "-" + months[month];

            if (incomeByMonth[yearMonth]) {
              incomeByMonth[yearMonth] += transaction.amount;
            } else {
              incomeByMonth[yearMonth] = transaction.amount;
            }
          }
        }
      });

      let incomeArray = [];
      for (let month = 0; month <= currentMonth; month++) {
        let yearMonth = currentYear + "-" + months[month];
        let totalIncome = incomeByMonth[yearMonth] || 0;
        incomeArray.push({ month: yearMonth, totalIncome: totalIncome });
      }

      return incomeArray;
    };

    const lineChartData = [
      {
        id: "Income",
        color: "hsl(91, 70%, 50%)",
        data: getTotalIncomePerMonth(filteredTransactions).map((income) => {
          return {
            x: income.month,
            y: income.totalIncome,
          };
        }),
      },
    ];

    //Expense
    // const getTotalExpensePerMonth = (transactions) => {
    //   let expenseByMonth = {};
    //   transactions.forEach((transaction) => {
    //     if (transaction.type === "Expense" && transaction.date_modified) {
    //       let date = new Date(transaction.date_modified);
    //       let yearMonth = date.getFullYear() + "-" + months[date.getMonth()];

    //       if (expenseByMonth[yearMonth]) {
    //         expenseByMonth[yearMonth] += transaction.amount;
    //       } else {
    //         expenseByMonth[yearMonth] = transaction.amount;
    //       }
    //     }
    //   });
    //   let expenseArray = [];
    //   for (let month = 1; month <= 12; month++) {
    //     let yearMonth = new Date().getFullYear() + "-" + months[month - 1];
    //     let totalIncome = expenseByMonth[yearMonth] || 0;
    //     expenseArray.push({ month: yearMonth, totalIncome: totalIncome });
    //   }

    //   return expenseArray;
    // };

    const getTotalExpensePerMonth = (transactions) => {
      let expenseByMonth = {};
      transactions.forEach((transaction) => {
        if (transaction.type === "Expense" && transaction.date_modified) {
          let date = new Date(transaction.date_modified);
          let yearMonth = date.getFullYear() + "-" + months[date.getMonth()];

          if (expenseByMonth[yearMonth]) {
            expenseByMonth[yearMonth] += transaction.amount;
          } else {
            expenseByMonth[yearMonth] = transaction.amount;
          }
        }
      });

      // Determine the current year and month
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();

      // Build expenseArray for the past months until the current month
      let expenseArray = [];
      for (let month = 0; month <= currentMonth; month++) {
        let yearMonth = currentYear + "-" + months[month];
        let totalIncome = expenseByMonth[yearMonth] || 0;
        expenseArray.push({ month: yearMonth, totalIncome: totalIncome });
      }

      return expenseArray;
    };

    const lineExpenseChartData = [
      {
        id: "Expense",
        color: "hsl(0, 53%, 58%)",
        data: getTotalExpensePerMonth(filteredTransactions).map((expense) => {
          return {
            x: expense.month,
            y: expense.totalIncome,
          };
        }),
      },
    ];
    //Loss Income
    const getTotalLossIncomePerMonth = (transactions) => {
      let lossIncomeByMonth = {};
      transactions.forEach((transaction) => {
        if (transaction.type === "Loss Income" && transaction.date_modified) {
          let date = new Date(transaction.date_modified);
          let yearMonth = date.getFullYear() + "-" + months[date.getMonth()];

          if (lossIncomeByMonth[yearMonth]) {
            lossIncomeByMonth[yearMonth] += transaction.amount;
          } else {
            lossIncomeByMonth[yearMonth] = transaction.amount;
          }
        }
      });
      // Determine the current year and month
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      let lossIncomeArray = [];
      for (let month = 1; month <= currentMonth + 1; month++) {
        let yearMonth = new Date().getFullYear() + "-" + months[month - 1];
        let totalIncome = lossIncomeByMonth[yearMonth] || 0;
        lossIncomeArray.push({ month: yearMonth, totalIncome: totalIncome });
      }

      return lossIncomeArray;
    };

    const lineLossIncomeChartData = [
      {
        id: "Loss Income",
        color: "hsl(180, 65%, 81%)",
        data: getTotalLossIncomePerMonth(filteredTransactions).map(
          (lossIncome) => {
            console.log(lossIncome);
            return {
              x: lossIncome.month,
              y: lossIncome.totalIncome,
            };
          }
        ),
      },
    ];

    return (
      <Container maxWidth="100%" height="200%">
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <WidgetCard
              title="Total Pigs"
              icon={<SavingsIcon fontSize="large" />}
              content={pigs.length}
              color={"#BAF5C1"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <WidgetCard
              title={`Monitoring this ${getMonth()}`}
              icon={<ContentPasteSearchIcon fontSize="large" />}
              content={filterByCurrentMonth(monitoringData).length}
              color={"#BAF5C1"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <WidgetCard
              title="Inventory Count"
              icon={<InventoryIcon fontSize="large" />}
              content={inventory.length}
              color={"#BAF5C1"}
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ height: "400px" }}>
            <LineChart
              data={lineChartData}
              legendX="Income Per Month"
              legendY="Amount"
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ height: "400px" }}>
            <LineChart
              data={lineExpenseChartData}
              legendX="Expense Per Month"
              legendY="Amount"
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ height: "400px" }}>
            <LineChart
              data={lineLossIncomeChartData}
              legendX="Loss income Per Month"
              legendY="Amount"
            />
          </Grid>
        </Grid>
      </Container>
    );
  }
  return <SuspenseFallback />;
};

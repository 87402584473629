import http from "./http";

export function getAllFeeds() {
  return http.get("/feed/list");
}

export function AddFeed(data) {
  return http.post("/feed/add", data);
}

export function getFeedById(id) {
  return http.get(`/feed/findbyid/${id}`);
}

export function editFeed(id, data) {
  return http.patch(`/feed/edit/${id}`, data);
}

export function getAllFeedTypes() {
  return http.get("/feedtypes/list");
}

export function addFeedTypes(data) {
  return http.post("/feedtypes/add", data);
}

export function deleteFeedTypes(id) {
  return http.delete(`/feedtypes/deleteitem/${id}`);
}

export function getAllFeedsAudit() {
  return http.get("/feed/audit/all");
}

export function deleteInventoryById(id) {
  return http.delete(`/feed/delete/${id}`);
}
export function getInventorybyname(name) {
  return http.get(`/feed/findbyname/${name}`);
}
export function getTransactionbyname(name) {
  return http.get(`/transactions/findbyname/${name}`);
}
export function getInventoryAudit(id) {
  return http.get(`/feed/audit/all/${id}`);
}

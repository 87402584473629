import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import QueryStatsIcon from "@mui/icons-material/QueryStats";

const WidgetCard = ({ title, icon, content, color }) => {
  return (
    <Card
      sx={{
        backgroundImage: `linear-gradient(to right, white, ${color})`,
        height: 120,
      }}
    >
      <CardHeader
        title={title}
        avatar={icon}
        action={
          <IconButton color="inherit">
            <QueryStatsIcon />
          </IconButton>
        }
        titleTypographyProps={{
          variant: title.length > 22 ? "body2" : "body1",
          fontWeight: "bold",
        }}
      />
      <CardContent>
        <Typography variant="h5">{content}</Typography>
      </CardContent>
    </Card>
  );
};

export default WidgetCard;

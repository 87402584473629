export const drawerWidth = 240;

export const title = "Greenland MPC";

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const uppercaseFirstLetter = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const getUnit = (value) => {
  if (value === "Vitamin") {
    return "unit";
  }
  if (value === "Feed") {
    return "sack";
  }
  if (value === "Equipment") {
    return "unit";
  }
  return null;
};

export const formatStandardDate = (dateString) => {
  const date = new Date(dateString);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  return date.toLocaleString(undefined, options);
};
